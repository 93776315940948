import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Component, OnInit, Input } from '@angular/core'
import { UtilService } from '@cms/services/util.service'
import * as moment from 'moment'
import { environment } from 'src/environments/environment'
import { AuthenService } from '@cms/services/authen.service'
import { OrderService } from '@cms/services/order.service'
import * as XLSX from 'xlsx'
import * as ELSX from 'xlsx-js-style'
import { NzModalService } from 'ng-zorro-antd/modal'
import { log } from 'ng-zorro-antd'
import { KetshopwebServices } from 'ketshopweb-services'
import { catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import * as datefns from 'date-fns'

@Component({
  selector: 'app-modal-export-order',
  templateUrl: './modal-export-order.component.html',
  styleUrls: ['./modal-export-order.component.css'],
})
export class ModalExportOrderComponent implements OnInit {
  @Input() data: any
  currentdatetime: any
  rangePicker: any = []
  access_token: string

  constructor(
    private Service_Util: UtilService,
    private Service_Order: OrderService,
    private _authen: AuthenService,
    private modal: NzModalService,
    private ReportService: KetshopwebServices.ReportService,
  ) {}

  model = {
    date_filter: 'created_at',
    startdate: '',
    enddate: '',
    status: [''],
    paymenttype: '' as number | string,
    type: 1 as number | string,
    has_prepared: false,
    created_by: '',
    tax: false,
  }
  api_path: string
  sunfordshop = false

  listOfSelectedValue = []
  disabledDate = null;

  async ngOnInit() {
    // ชั่วคราว
    if (location.origin.includes('sunfordshop.com')) {
      this.sunfordshop = true
    }
    if(location.hostname == 'shopee.ketshopweb.com'){
      
      this.disabledDate = (current:Date) => {
      let past_month = moment().subtract(3, 'months').format('YYYY/MM/DD 00:00:00');
       return  (datefns.differenceInCalendarDays(current,new Date(past_month)) < 0) || (datefns.differenceInCalendarDays(current,new Date()) > 0)
      };
    }

    this.api_path = environment.api_path
    this.access_token = this._authen.getToken().access_token
    this.currentdatetime = await this.Service_Util.getDateTime()
    this.rangePicker = [
      moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss'),
      moment(this.currentdatetime.date).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    ]
    this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD')
    this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD')
    if (this.data.created_by) {
      this.model.created_by = this.data.created_by
    }
    console.log(this.rangePicker)
  }

  dateRangeChange() {
    console.log(this.rangePicker)
    try {
      this.model.startdate = moment(this.rangePicker[0]).format('YYYY-MM-DD HH:mm:ss')
      this.model.enddate = moment(this.rangePicker[1]).format('YYYY-MM-DD HH:mm:ss')
    } catch (e) {
      this.model.startdate = moment(this.currentdatetime.date).add(-1, 'month').format('YYYY-MM-DD HH:mm:ss')
      this.model.enddate = moment(this.currentdatetime.date).format('YYYY-MM-DD HH:mm:ss')
    }
    console.log(this.model)
  }

  async export() {
    $('.theme-loader').show()
    let filename = ''
    if (this.model.type == 1) {
      filename = 'สรุปยอดบิล'
    } else if (this.model.type == 2) {
      filename = 'สรุปยอดบิลและรายละเอียด'
    } else if (this.model.type == 3) {
      filename = 'รายการสินค้าที่ขายได้'
    } else if (this.model.type == 4) {
      filename = 'สรุปยอดทุกช่องทาง'
    } else if (this.model.type == 5) {
      filename = 'สรุปยอดบิลและสินค้า'
    } else if (this.model.type == 6) {
      filename = 'สรุปส่วนลด Marketplace'
    }
    const res_data = {} as any
    let is_complete = false
    const sub = this.ReportService.export_order(this.model)
      .pipe(
        catchError((err) => {
          console.error(err)
          $('.theme-loader').hide()
          return of({
            type: 'complete',
            data: null,
          })
        }),
      )
      .subscribe((stm) => {
        console.log(stm)

        if (stm.type == 'complete') {
          sub.unsubscribe()
          is_complete = true
        }
        if (!is_complete) {
          if (stm.data) {
            const raw_data = stm.data as {
              value: any
              key: string
              is_array: boolean
            }
            if (raw_data.is_array) {
              if (!res_data[raw_data.key]) {
                res_data[raw_data.key] = []
              }
              res_data[raw_data.key].push(raw_data.value)
            } else {
              res_data[raw_data.key] = raw_data.value
            }
          }
          return
        }
        const r = res_data

        let res = []
        if (r.order) {
          res = r.order
        }

        const data: any[] = []

        const order_status = {
          '-1': 'ยกเลิก',
          '0': 'Pending',
          '1': 'รอชำระเงิน',
          '2': 'ได้รับเงิน',
          '3': 'จัดเตรียมสินค้า',
          '3.5': 'เตรียมจัดส่ง',
          '4': 'จัดส่ง',
          '5': 'เสร็จสิ้น',
          '': 'ทุกสถานะ',
        }

        const patment_status = {
          '1': 'ชำระเงินสด',
          '2': 'โอนเงินผ่านธนาคาร',
          '3': 'Credit Card',
          '4': 'เก็บเงินปลายทาง',
          '5': 'QR Code',
          '': 'ทั้งหมด',
        }

        const head_style = {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true,
          },
          border: {
            left: { style: 'thin', color: '000000' },
            right: { style: 'thin', color: '000000' },
            top: { style: 'thin', color: '000000' },
            bottom: { style: 'thin', color: '000000' },
          },
          font: {
            sz: 16,
            name: 'Angsana New',
            color: '000000',
            bold: true,
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { rgb: 'b1b1b1' },
          },
        }

        const _style = {
          alignment: {
            vertical: 'center',
            horizontal: 'center',
            wrapText: true,
          },
          font: {
            bold: true,
            sz: 16,
            name: 'Angsana New',
            color: '000000',
          },
        }

        const body_style = {
          font: {
            sz: 16,
            name: 'Angsana New',
            color: '000000',
          },
        }

        const styleheaderrow = {
          font: {
            sz: 16,
            name: 'Angsana New',
            color: '000000',
          },
        }

        const last_style = {
          font: {
            sz: 16,
            name: 'Angsana New',
            color: '000000',
          },
          border: {
            bottom: { style: 'thin', color: '000000' },
          },
        }

        const last_style_end = {
          font: {
            sz: 16,
            name: 'Angsana New',
            color: '000000',
          },
          border: {
            bottom: { style: 'thick', color: '000000' },
          },
        }

        let ind = 1

        if (res) {
          for (let i = 0; i < res.length; i++) {
            const obj = res[i]

            let status = order_status[obj.status]
            if (obj.order_complete == 1) {
              status = 'เสร็จสิ้น'
            }
            let customer = ''
            if (obj.customer && obj.customer.id) {
              customer = obj.customer.id
            }

            let delivery = ''
            if (obj.purchase_id == null && obj.freeshipping == 0) {
              try {
                delivery = obj.delivery.name
              } catch (e) {}
            } else {
              if (obj.freeshipping) {
                if (obj.shippop) {
                  delivery = this.shippopName(obj.shippop.courier_code)
                } else {
                  delivery = 'ทางร้านเป็นผู้จัดส่ง'
                }
              } else {
                delivery = this.shippopName(obj.delivery.courier_code)
              }
            }

            let box = ''
            if (obj.box_name == null) {
              box = ''
            } else {
              box = obj.box_name + `(${obj.box_value})`
            }

            const freeshipping = obj.freeshipping ? 'ฟรี' : 'ไม่ฟรี'

            let coupon = ''
            let promotion_title = ''
            if (obj.promotion.length > 0) {
              if (obj.promotion[0].promotion_type == 'coupon') {
                coupon = obj.promotion[0].coupon
                promotion_title = obj.promotion[0].promotion_title
              }
            }

            let confirmpayment_date = ''
            if (obj.confirmpayments.length > 0) {
              confirmpayment_date =
                moment(obj.confirmpayments[0].date).format('YYYY/MM/DD') + ` ${obj.confirmpayments[0].time}`
            }
            console.log(confirmpayment_date)

            let channel = ''
            if (obj.channel) {
              channel = obj.channel
            } else if (obj.user_id == -1) {
              channel = 'app'
            } else {
              channel = 'website'
            }
            let subch = ''
            if (obj.qt && obj.qt.channel_social_name) {
              subch = obj.qt.channel_social_name
            } else {
              if (obj.channel_name) {
                subch = obj.channel_name
              }
            }

            let prepared_shipping = ''
            if (obj.shipping_group) {
              prepared_shipping = obj.shipping_group.prepared_by
            }
            let model: any
            if (this.model.type == 1) {
              model = {
                เลขที่เอกสาร: obj.ordercode || '',
                วันที่สั่งซื้อ: obj.order_date || '',
                วันที่เข้าระบบ: obj.created_at || '',
                สถานะ: status || '',
                แก้ไขล่าสุด: obj.updated_at || '',
                รหัสลูกค้า: customer || '',
                ลูกค้า: obj.name || '',
                จำนวน: obj.details.length || '',
                รวม: obj.amount || '',
                ส่วนลดราคา: obj.discount + obj.discountaddon + obj.discount_point || '',
                ภาษีมูลค่าเพิ่ม: obj.vat || '',
                '%ภาษี': obj.vatrate || '',
                ค่าขนส่ง: obj.delicharge || '',
                'DeliveryCharge Addon': obj.delichargeaddon || '',
                'ค่าบริการ COD': obj.cod_value || '',
                ราคารวมทั้งหมด: obj.totals || '',
                จัดส่งโดย: delivery || '',
                เลขพัสดุ: obj.trackcode || '',
                ขนาดกล่อง: box || '',
                น้ำหนัก: obj.weight || '',
                ที่อยู่จัดส่ง: obj.address1,
                'แขวง/ตำบล': obj.subdistrict || '',
                'เขต/อำเภอ': obj.district || '',
                จังหวัด: obj.province || '',
                รหัสไปรษณีย์: obj.zipcode || '',
                ประเทศ: obj.country_name || '',
                อีเมล์: obj.customer ? obj.customer.username : '',
                เบอร์ติดต่อ: obj.tel || '',
                หมายเหตุลูกค้า: obj.customer_note || '',
                หมายเหตุภายใน: obj.note || '',
                เลขนิติบุคคล: obj.tax_no || '',
                เลขภาษีบุคคล: obj.tax_no || '',
                วิธีชำระ: patment_status[obj.paymenttype.id] || '',
                จัดส่งฟรี: freeshipping || '',
                'Create By': obj.created_by || '',
                รหัสโปรโมชั่น: coupon || '',
                โปรโมชั่น: promotion_title || '',
                'เลขออเดอร์จาก Marketplace': confirmpayment_date || '',
                ช่องทาง: channel || '',
                ร้านค้า: subch || '',
                'member level': (obj.customer && obj.customer.member_level && obj.customer.member_level.title) || '',
                จัดสินค้าโดย: obj.prepared_by || '',
                เบิกแพ็คโดย: prepared_shipping || '',
              }

              data.push(model)
            } else if (this.model.type == 2) {
              let Created_By = ''
              if (obj.channel && obj.channel != 'website') {
                channel = obj.channel
                Created_By = obj.created_by
              } else if (obj.user_id == -1) {
                channel = 'app'
                Created_By = 'app'
              } else {
                channel = 'website'
                Created_By = obj.created_by
              }

              model = {
                '#': ind,
                วันที่สั่งซื้อ: obj.order_date || '',
                วันที่เข้าระบบ: obj.created_at || '',
                เลขที่เอกสาร: obj.ordercode,
                เลขพัสดุ: obj.trackcode || '',
                สถานะ: status || '',
                เลขนิติบุคคล: obj.tax_no || '',
                เลขภาษีบุคคล: obj.tax_no || '',
                รหัสลูกค้า: customer || '',
                ลูกค้า: obj.name || '',
                อีเมล์: obj.customer ? obj.customer.username : '',
                เบอร์ติดต่อ: obj.tel || '',
                ที่อยู่จัดส่ง: obj.address1 || '',
                'แขวง/ตำบล': obj.subdistrict || '',
                'เขต/อำเภอ': obj.district || '',
                จังหวัด: obj.province || '',
                รหัสไปรษณีย์: obj.zipcode || '',
                ประเทศ: obj.country_name || '',
                หมายเหตุลูกค้า: obj.customer_note || '',
                หมายเหตุภายใน: obj.note || '',
                sku: '',
                ชื่อสินค้า: '',
                หมวดหมู่สินค้า: '',
                ประเภทสินค้า: '',
                จำนวน: '',
                ราคาต่อชิ้น: '',
                รวม: obj.amount || 0,
                'ส่วนลดโปรโมชั่น\n(เงื่อนไข/คูปอง)': obj.discount || 0,
                'ส่วนลดพิเศษ\n(ลดท้ายบิล)': obj.discountaddon || 0,
                ส่วนลดแต้ม: obj.discount_point || 0,
                'ราคา Vat': obj.vat || 0,
                'ราคารวม Vat': obj.amount - obj.discount - obj.discountaddon + obj.vat || 0,
                ค่าขนส่ง: obj.delicharge + obj.delichargeaddon || 0,
                'ค่าบริการ COD': obj.cod_value || 0,
                ราคารวมทั้งหมด: obj.totals || 0,
                รหัสโปรโมชั่น: '',
                โปรโมชั่น: '',
                แฟลชเซลล์: '',
                วิธีชำระ: patment_status[obj.paymenttype.id] || '',
                วันที่โอน: confirmpayment_date || '',
                'เลขออเดอร์จาก\nMarketplace': obj.channel_ordercode || '',
                ช่องทาง: channel || '',
                ร้านค้า: subch || '',
                จัดส่งโดย: delivery || '',
                จัดส่่งฟรี: freeshipping || '',
                'Created By': Created_By || '',
                'member level': (obj.customer && obj.customer.member_level && obj.customer.member_level.title) || '',
                จัดสินค้าโดย: obj.prepared_by || '',
                เบิกแพ็คโดย: prepared_shipping || '',
              }
              data.push(model)
              ind++

              const is = data.length
              let o = 0
              for (const ord of obj.details) {
                let size = ''
                if (ord.prop_enable == 1) {
                  size += `( ${ord.property_info} )`
                } else if (ord.prop_enable == 2) {
                  size += `( ${ord.property_info} - ${ord.property_info2} )`
                }

                let product_category_lang1 = ''
                if (ord.product_category) {
                  product_category_lang1 = ord.product_category.lang1
                }

                let promotion_title = ''
                if (ord.order_promotion[o]) {
                  let promotion: any
                  promotion_title = ''
                  for (const index of obj.promotion) {
                    if (index.id == ord.order_promotion[o].id) {
                      promotion = ord.order_promotion[o]
                    }
                  }

                  if (promotion && promotion.promotion_type) {
                    if (promotion.promotion_type == 'coupon' || promotion.promotion_type == 'condition') {
                      if (promotion.coupon && promotion.promotion_type == 'coupon') {
                        coupon = promotion.coupon
                      }
                      if (promotion.title) {
                        promotion_title = promotion.title
                      }
                    }
                  }
                  o++

                  data[is - 1]['รหัสโปรโมชั่น'] = coupon || ''
                  data[is - 1]['โปรโมชั่น'] = promotion_title || ''
                }

                let promotion_name = ''
                if (ord.promotion && ord.promotion.length > 0 && ord.promotion[0].promotion_type == 'flash_sale') {
                  promotion_name = 'ใช้' + ord.promotion[0].promotion_title
                }
                model = {
                  '#': '',
                  วันที่สั่งซื้อ: obj.order_date || '',
                  วันที่เข้าระบบ: obj.created_at || '',
                  เลขที่เอกสาร: obj.ordercode || '',
                  เลขพัสดุ: obj.trackcode || '',
                  สถานะ: status || '',
                  เลขนิติบุคคล: obj.tax_no || '',
                  เลขภาษีบุคคล: obj.tax_no || '',
                  รหัสลูกค้า: customer || '',
                  ลูกค้า: obj.name || '',
                  อีเมล์: obj.customer ? obj.customer.username : '',
                  เบอร์ติดต่อ: obj.tel || '',
                  ที่อยู่จัดส่ง: '',
                  'แขวง/ตำบล': '',
                  'เขต/อำเภอ': '',
                  จังหวัด: '',
                  รหัสไปรษณีย์: '',
                  ประเทศ: '',
                  หมายเหตุลูกค้า: '',
                  หมายเหตุภายใน: '',
                  sku: ord.sku || '',
                  ชื่อสินค้า: `${ord.title_lang1} ${size}` || '',
                  หมวดหมู่สินค้า: product_category_lang1 || '',
                  ประเภทสินค้า: 'ขาย',
                  จำนวน: ord.qty || 0,
                  ราคาต่อชิ้น: ord.product_price || 0,
                  รวม: ord.price || 0,
                  'ส่วนลดโปรโมชั่น\n(เงื่อนไข/คูปอง)': '',
                  'ส่วนลดพิเศษ\n(ลดท้ายบิล)': '',
                  ส่วนลดแต้ม: '',
                  'ราคา Vat': '',
                  'ราคารวม Vat': '',
                  ค่าขนส่ง: '',
                  'ค่าบริการ COD': '',
                  ราคารวมทั้งหมด: '',
                  รหัสโปรโมชั่น: '',
                  โปรโมชั่น: '',
                  แฟลชเซลล์: promotion_name || '',
                  วิธีชำระ: patment_status[obj.paymenttype.id] || '',
                  วันที่โอน: confirmpayment_date || '',
                  'เลขออเดอร์จาก\nMarketplace': ord.channel_ordercode || '',
                  ช่องทาง: channel || '',
                  ร้านค้า: subch || '',
                  จัดส่งโดย: delivery || '',
                  จัดส่่งฟรี: freeshipping || '',
                  'Created By': Created_By || '',
                  'member level': (obj.customer && obj.customer.member_level && obj.customer.member_level.title) || '',
                  จัดสินค้าโดย: obj.prepared_by || '',
                  เบิกแพ็คโดย: prepared_shipping || '',
                }
                data.push(model)
              }
              if (obj.free_item.length > 0) {
                for (const ord of obj.free_item) {
                  let size = ''
                  if (ord.prop_enable == 1) {
                    size += `( ${ord.property_info} )`
                  } else if (ord.prop_enable == 2) {
                    size += `( ${ord.property_info} - ${ord.property_info2} )`
                  }

                  let product_category_lang1 = ''
                  if (ord.product_category) {
                    product_category_lang1 = ord.product_category.lang1
                  }
                  let promotion_type = 'ขาย'
                  if (obj.free_item.length > 0) {
                    if (obj.free_item[0].promotion_type == 'free_item') {
                      promotion_type = 'แถม'
                    }
                    if (obj.free_item[0].promotion_type == 'product_point') {
                      promotion_type = 'แลกแต้ม'
                    }
                  }

                  let promotion_name = ''
                  if (ord.promotion && ord.promotion.length > 0 && ord.promotion[0].promotion_type == 'flash_sale') {
                    promotion_name = 'ใช้' + ord.promotion[0].promotion_title
                  }
                  model = {
                    '#': '',
                    วันที่สั่งซื้อ: obj.order_date || '',
                    วันที่เข้าระบบ: obj.created_at || '',
                    เลขที่เอกสาร: obj.ordercode || '',
                    เลขพัสดุ: obj.trackcode || '',
                    สถานะ: status || '',
                    เลขนิติบุคคล: obj.tax_no || '',
                    เลขภาษีบุคคล: obj.tax_no || '',
                    รหัสลูกค้า: customer || '',
                    ลูกค้า: obj.name || '',
                    อีเมล์: obj.customer ? obj.customer.username : '',
                    เบอร์ติดต่อ: obj.tel || '',
                    ที่อยู่จัดส่ง: '',
                    'แขวง/ตำบล': '',
                    'เขต/อำเภอ': '',
                    จังหวัด: '',
                    รหัสไปรษณีย์: '',
                    ประเทศ: '',
                    หมายเหตุลูกค้า: '',
                    หมายเหตุภายใน: '',
                    sku: ord.sku || '',
                    ชื่อสินค้า: `${ord.title_lang1} ${size}` || '',
                    หมวดหมู่สินค้า: product_category_lang1 || '',
                    ประเภทสินค้า: promotion_type || '',
                    จำนวน: ord.qty || '',
                    ราคาต่อชิ้น: ord.product_price || '',
                    รวม: ord.price || '',
                    'ส่วนลดโปรโมชั่น\n(เงื่อนไข/คูปอง)': '',
                    'ส่วนลดพิเศษ\n(ลดท้ายบิล)': '',
                    ส่วนลดแต้ม: '',
                    'ราคา Vat': '',
                    'ราคารวม Vat': '',
                    ค่าขนส่ง: '',
                    'ค่าบริการ COD': '',
                    ราคารวมทั้งหมด: '',
                    รหัสโปรโมชั่น: '',
                    โปรโมชั่น: '',
                    แฟลชเซลล์: promotion_name || '',
                    วิธีชำระ: patment_status[obj.paymenttype.id] || '',
                    วันที่โอน: confirmpayment_date || '',
                    'เลขออเดอร์จาก\nMarketplace': ord.channel_ordercode || '',
                    ช่องทาง: channel || '',
                    ร้านค้า: subch || '',
                    จัดส่งโดย: delivery || '',
                    จัดส่่งฟรี: freeshipping || '',
                    'Created By': Created_By || '',
                    'member level':
                      (obj.customer && obj.customer.member_level && obj.customer.member_level.title) || '',
                    จัดสินค้าโดย: obj.prepared_by || '',
                    เบิกแพ็คโดย: prepared_shipping || '',
                  }
                  data.push(model)
                }
              }
            } else if (this.model.type == 3) {
              for (const ord of obj.details) {
                let size = ''
                if (ord.prop_enable == 1) {
                  size += `( ${ord.property_info} )`
                } else if (ord.prop_enable == 2) {
                  size += `( ${ord.property_info} - ${ord.property_info2} )`
                }

                let title = ''
                if (ord.title_lang1 != null) {
                  title = `${ord.title_lang1} ${size}`
                }

                let product_category_lang1 = ''
                if (ord.product_category) {
                  product_category_lang1 = ord.product_category.lang1
                }
                model = {
                  '#': ind,
                  เลขที่เอกสาร: obj.ordercode || '',
                  วันที่สั่งซื้อ: obj.order_date || '',
                  วันที่เข้าระบบ: obj.created_at || '',
                  สถานะ: status || '',
                  วิธีชำระ: patment_status[obj.paymenttype.id] || '',
                  ช่องทาง: channel || '',
                  ร้านค้า: subch || '',
                  sku: ord.sku || '',
                  ชื่อสินค้า: title || '',
                  ประเภทสินค้า: product_category_lang1 || '',
                  จำนวน: ord.qty || '',
                  ราคาต่อชิ้น: ord.product_price || '',
                  รวม: ord.price || '',
                  'Customer Name': (obj.customer && obj.customer.name) || '',
                  'Customer-id': obj.customer ? obj.customer.id : '',
                  'Create By': obj.created_by || '',
                  'member level': (obj.customer && obj.customer.member_level && obj.customer.member_level.title) || '',
                  จัดสินค้าโดย: obj.prepared_by || '',
                  เบิกแพ็คโดย: prepared_shipping || '',
                }
                data.push(model)
                ind++
              }
            } else if (this.model.type == 5) {
              for (let k = 0; k < obj.details.length; k++) {
                const _detail = obj.details[k]
                let coupon = ''
                let promotion_title = ''
                if (obj.promotion.length > 0) {
                  if (obj.promotion[0].promotion_type == 'coupon') {
                    coupon = obj.promotion[0].coupon
                    promotion_title = obj.promotion[0].promotion_title
                  }
                }

                model = {
                  '#': ind,
                  วันที่ออก: obj.order_date || '',
                  เลขที่เอกสาร: obj.ordercode || '',
                  เลขพัสดุ: obj.trackcode,
                  สถานะ: status || '',
                  เลขนิติบุคคล: obj.tax_no || '',
                  ลูกค้า: obj.name || '',
                  อีเมล์: obj.customer ? obj.customer.username : '',
                  เบอร์ติดต่อ: obj.tel || '',
                  ที่อยู่จัดส่ง: obj.address1 || '',
                  'แขวง/ตำบล': obj.subdistrict || '',
                  'เขต/อำเภอ': obj.district || '',
                  จังหวัด: obj.province || '',
                  รหัสไปรษณีย์: obj.zipcode || '',
                  เลขภาษี: obj.tax_no || '',
                  หมายเหตุลูกค้า: obj.customer_note || '',
                  sku: _detail.sku || '',
                  ชื่อสินค้า: _detail.title_lang1 || '',
                  จำนวน: _detail.qty || 0,
                  ราคาต่อชิ้น: _detail.product_price || 0,
                  รวม: _detail.price || 0,
                  ค่าขนส่ง: obj.delicharge + obj.delichargeaddon || 0,
                  'ค่าบริการ COD': obj.cod_value || 0,
                  โปรโมชั่น: coupon || '',
                  วิธีชำระ: patment_status[obj.paymenttype.id] || '',
                  วันที่โอน: confirmpayment_date || '',
                  ช่องทาง: obj.channel || '',
                  'member level': (obj.customer && obj.customer.member_level && obj.customer.member_level.title) || '',
                }
                data.push(model)
                ind++
              }
            }
          }
        }

        const SheetNames = `Raw-Data${moment(this.model.startdate).format('YYYY-MM-DD')}${moment(
          this.model.enddate,
        ).format('YYYY-MM-DD')}`
        try {
          const wb = XLSX.utils.book_new()
          wb.Props = {
            Title: SheetNames,
            CreatedDate: new Date(),
          }
          let ws: any

          if (this.model.type == 3) {
            const a = this.createWorkSheetReport(res)

            const value = []
            for (const obj of a) {
              const model = {
                SKU: obj.sku || '',
                'Product Name': obj.title_lang1 || '',
                'All Amounts': obj.qty || 0,
                รอชำระ: obj.payment || 0,
                ได้รับเงิน: obj.paid || 0,
                จัดเตรียม: obj.prepare || 0,
                เตรียมจัดส่ง: obj.prepare_delivery || 0,
                จัดส่ง: obj.delivery || 0,
                เสร็จสิ้น: obj.success || 0,
                ยกเลิก: obj.cancel || 0,
              }
              value.push(model)
            }

            wb.SheetNames.push('Report')
            const w = XLSX.utils.json_to_sheet(value)

            for (const name in w) {
              if (!/[A-Z][1]/.test(name)) continue
              if (/[1]/.test(name[1]) && name.length > 2) continue
              if (w[name] && w[name].v) {
                w[name].s = head_style
              }
            }
            w['!cols'] = [
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
              { width: 20 },
            ]
            w['!rows'] = [{ hpt: 40 }]
            for (let index = 0; index < a.length; index++) {
              w['!rows'].push({ hpt: 25 })
            }
            wb.Sheets['Report'] = w
          } else if (this.model.type == 4) {
            if (r.order_chanel.length == 0) {
              let name = ''
              const paymenttype = `โดย ${patment_status[this.model.paymenttype]}`

              if (this.model.status.length == 0) {
                name = `ไม่มีออเดอร์ สถานะทั้งหมด โดยการชำระเงินทั้งหมด`
              } else {
                let text = ''
                for (const status of this.model.status) {
                  if (text == '') {
                    text += order_status[status]
                  } else {
                    text += 'และ' + order_status[status]
                  }
                }
                name = `ไม่มีออเดอร์  สถานะ ${text} ${paymenttype}`
              }
              this.modal.warning({
                nzTitle: name,
                nzContent: '',
              })
              $('.theme-loader').hide()
              return
            }
            for (const order_chanel of r.order_chanel) {
              const details = [
                [[r.customerInfo.address]],
                [['ยอดขายช่องทาง ' + order_chanel.chanel]],
                [['สรุปยอดขายประจำวันที่ ' + this.model.startdate + ' ถึง ' + this.model.enddate]],
                [],
                [
                  ['วันที่ทำการสั่งซื้อ'],
                  ['หมายเลขคำสั่งซื้อ'],
                  ['สถานะการสั่งซื้อ'],
                  ['ชื่อผู้ใช้ (ผู้ซื้อ)'],
                  ['ราคาขายสุทธิ'],
                  ['โค้ดส่วนลดชำระโดยผู้ขาย'],
                  ['ค่าจัดส่งที่ชำระโดยผู้ซื้อ'],
                  ['มูลค่ารวมก่อนภาษี'],
                  ['ภาษีมูลค่าเพิ่ม'],
                  ['จำนวนเงินทั้งหมด'],
                  ['สถานะการชำระเงิน'],
                  ['เลขพัสดุ'],
                  ['วิธีการชำระเงิน'],
                  ['หมายเหตุลูกค้า'],
                  ['หมายเหตุภายใน'],
                  ['member level'],
                  ['จัดสินค้าโดย'],
                  ['เบิกแพ็คโดย'],
                ],

                // เพิ่มรายละเอียดอื่นๆ ตามต้องการ
              ]

              let sum_amount = 0
              let sum_discount = 0
              let sum_delicharge = 0
              let sum = 0
              let sum_vat = 0
              let sum_totals = 0

              for (const obj of order_chanel.data) {
                let prepared_shipping = ''
                if (obj.shipping_group) {
                  prepared_shipping = obj.shipping_group.prepared_by
                }

                let status = order_status[obj.status]
                if (obj.order_complete == 1) {
                  status = 'เสร็จสิ้น'
                }

                let paymentstatus = 'สำเร็จ'
                if (obj.paymenttype.status == 0) {
                  paymentstatus = 'รอการชำระเงิน'
                }
                details.push([
                  [obj.order_date || ''],
                  [obj.ordercode || ''],
                  [status || ''],
                  [obj.name || ''],
                  [obj.amount || '0'],
                  [obj.discount + obj.discountaddon + obj.discount_point || 0],
                  [obj.delicharge + obj.delichargeaddon || 0],
                  [obj.amount + obj.delicharge || 0],
                  [obj.vat || 0],
                  [obj.totals || 0],
                  [paymentstatus || ''],
                  [obj.payment || ''],
                  [patment_status[obj.paymenttype.id] || ''],
                  [obj.customer_note || ''],
                  [obj.note || ''],
                  [(obj.customer && obj.customer.member_level && obj.customer.member_level.title) || ''],
                  [obj.prepared_by || ''],
                  [prepared_shipping || ''],
                ])
                sum_amount += obj.amount
                sum_discount += obj.discount + obj.discountaddon + obj.discount_point
                sum_delicharge += obj.delicharge + obj.delichargeaddon
                sum += obj.amount + obj.delicharge
                sum_vat += obj.vat
                sum_totals += obj.totals
              }
              details.push([
                [],
                [],
                [],
                ['รวมจำนวนเงินทั้งสิ้น'],
                [sum_amount],
                [sum_discount],
                [sum_delicharge],
                [sum],
                [sum_vat],
                [sum_totals],
                ['บาท'],
              ])

              details.push([[], [], [], [], ['A'], ['B'], ['C']])

              details.push([[], [], [], ['ยอดขาย(รวมภาษี)A+B'], [sum_amount + sum_discount || 0], ['บาท']])
              details.push([[], [], [], ['ค่าขนส่ง(รวมภาษี)C'], [sum_delicharge || 0], ['บาท']])
              details.push([[], [], [], ['รายได้(ก่อนภาษี)'], [sum || 0], ['บาท']])
              details.push([[], [], [], ['VAT 7%'], [sum_vat || 0], ['บาท']])
              details.push([[], [], [], ['รวมทั้งสิ้น'], [sum_totals || 0], ['บาท']])

              wb.SheetNames.push(order_chanel.chanel)
              ws = XLSX.utils.sheet_add_aoa({}, details)
              const mergeRanges = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } }, // A1:K1
                { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } }, // A2:K2
                { s: { r: 2, c: 0 }, e: { r: 2, c: 10 } }, // A3:K3
              ]
              ws['!merges'] = mergeRanges

              for (const name in ws) {
                if (ws[name] && ws[name].t) {
                  ws[name].s = body_style
                }
              }

              const indexx = details.length - 5
              const index_ws = details.length - 2

              for (let index = indexx; index < index_ws; index++) {
                for (let i = 68; i <= 70; i++) {
                  const letter = String.fromCharCode(i) + (index + 1).toString()
                  ws[letter].s = styleheaderrow
                }
              }
              for (let i = 68; i <= 70; i++) {
                const letter = String.fromCharCode(i) + (index_ws + 1).toString()
                ws[letter].s = last_style
              }

              for (let i = 68; i <= 70; i++) {
                const letter = String.fromCharCode(i) + (index_ws + 2).toString()
                ws[letter].s = last_style_end
              }
              for (const name in ws) {
                if (!/[A-Z][1-3]/.test(name)) continue
                if (/[1-3]/.test(name[1]) && name.length > 2) continue

                if (name.length > 2) {
                  if (/[0-9]/.test(name[1])) {
                    continue
                  }
                }
                if (name.length > 3) continue
                if (ws[name] && ws[name].t) {
                  ws[name].s = _style
                }
              }

              for (const name in ws) {
                if (!/[A-Z][5]/.test(name)) continue
                if (/[5]/.test(name[1]) && name.length > 2) continue

                if (name.length > 2) {
                  if (/[0-9]/.test(name[1])) {
                    continue
                  }
                }
                if (name.length > 3) continue
                if (ws[name] && ws[name].t) {
                  ws[name].s = head_style
                }
              }
              ws['!cols'] = []

              for (const name in ws) {
                if (!/[A-Z][5]/.test(name)) continue
                if (name.length > 2) {
                  if (/[0-9]/.test(name[1])) {
                    continue
                  }
                }
                if (name.length > 3) continue
                ws['!cols'].push({ width: 20 })
              }

              ws['!rows'] = [{ hpt: 40 }]
              for (let index = 0; index < details.length; index++) {
                ws['!rows'].push({ hpt: 25 })
              }

              wb.Sheets[order_chanel.chanel] = ws
            }
          } else if (this.model.type == 6) {
            if (!r.order_detial_json) {
              this.modal.warning({
                nzTitle: 'ไม่มีข้อมูล สรุปส่วนลด Marketplace',
                nzContent: '',
              })
              $('.theme-loader').hide()
              return
            }
            if (r.order_detial_json.length == 0) {
              this.modal.warning({
                nzTitle: 'ไม่มีข้อมูล สรุปส่วนลด Marketplace',
                nzContent: '',
              })
              $('.theme-loader').hide()
              return
            }

            const details = [
              [[`สรุปส่วนลด Marketplace ประจำวันที่ ${this.model.startdate} ถึง ${this.model.enddate}`]],
              [],
              [['Channel'], ['ชื่อร้าน'], ['มูลค่าส่วนลด Marketplace']],
            ]
            const ketshopOrder = [
              [
                ['Channel'],
                ['ชื่อร้าน'],
                ['มูลค่าส่วนลด Marketplace'],
                ['Ketshop Order'],
                ['Marketplace Order'],
                ['วันที่เกิดออเดอร์'],
              ],
            ]

            for (const summary of r.summary_json) {
              details.push([[summary.channel || ''], [summary.channel_name || ''], [summary.total || 0]])
            }
            for (const order_detial of r.order_detial_json) {
              ketshopOrder.push([
                [order_detial.channel || ''],
                [order_detial.channel_name || ''],
                [order_detial.price || 0],
                [order_detial.ordercode || ''],
                [order_detial.channel_ordercode || ''],
                [moment(order_detial.order_date).format('YYYY/MM/DD HH:mm:ss') || ''],
              ])
            }
            wb.SheetNames.push('สรุป')
            ws = XLSX.utils.sheet_add_aoa(ws, details)

            const mergeRanges = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 2 } }]
            ws['!merges'] = mergeRanges

            for (const name in ws) {
              if (ws[name] && ws[name].t) {
                ws[name].s = body_style
              }
            }

            for (const name in ws) {
              if (!/[A-Z][1]/.test(name)) continue
              if (/[1]/.test(name[1]) && name.length > 2) continue

              if (name.length > 2) {
                if (/[0-9]/.test(name[1])) {
                  continue
                }
              }
              if (name.length > 3) continue
              if (ws[name] && ws[name].t) {
                ws[name].s = _style
              }
            }

            for (const name in ws) {
              if (!/[A-Z][3]/.test(name)) continue
              if (/[3]/.test(name[1]) && name.length > 2) continue

              if (name.length > 2) {
                if (/[0-9]/.test(name[1])) {
                  continue
                }
              }
              if (name.length > 3) continue
              if (ws[name] && ws[name].t) {
                ws[name].s = head_style
              }
            }

            ws['!cols'] = [{ width: 30 }, { width: 30 }, { width: 30 }]
            ws['!rows'] = [{ hpt: 40 }]

            for (let index = 0; index < details.length; index++) {
              ws['!rows'].push({ hpt: 25 })
            }

            wb.Sheets['สรุป'] = ws
            wb.SheetNames.push('รายละเอียด')
            const ws2 = XLSX.utils.sheet_add_aoa({}, ketshopOrder)

            for (const name in ws2) {
              if (ws2[name] && ws2[name].t) {
                ws2[name].s = body_style
              }
            }

            for (const name in ws2) {
              if (!/[A-Z][1]/.test(name)) continue
              if (/[3]/.test(name[1]) && name.length > 2) continue

              if (name.length > 2) {
                if (/[0-9]/.test(name[1])) {
                  continue
                }
              }
              if (name.length > 3) continue
              if (ws2[name] && ws2[name].t) {
                ws2[name].s = head_style
              }
            }

            ws2['!cols'] = [{ width: 30 }, { width: 30 }, { width: 30 }, { width: 20 }, { width: 20 }, { width: 20 }]
            ws2['!rows'] = [{ hpt: 40 }]
            for (let index = 0; index < ketshopOrder.length; index++) {
              ws2['!rows'].push({ hpt: 25 })
            }
            wb.Sheets['รายละเอียด'] = ws2
          }

          if (this.model.type !== 4 && this.model.type !== 6) {
            wb.SheetNames.push(SheetNames)
            ws = XLSX.utils.json_to_sheet(data)

            const body_ = {
              font: {
                sz: 16,
                name: 'Angsana New',
                color: '000000',
              },
            }
            for (const name in ws) {
              if (ws[name] && ws[name].t) {
                ws[name].s = body_
              }
            }
            for (const name in ws) {
              if (!/[A-Z][1]/.test(name)) continue
              if (/[1]/.test(name[1]) && name.length > 2) continue

              if (name.length > 2) {
                if (/[0-9]/.test(name[1])) {
                  continue
                }
              }
              if (name.length > 3) continue
              if (ws[name] && ws[name].t) {
                ws[name].s = head_style
              }
            }
            ws['!cols'] = []

            for (const name in ws) {
              if (!/[A-Z][1]/.test(name)) continue
              if (name.length > 2) {
                if (/[0-9]/.test(name[1])) {
                  continue
                }
              }
              if (name.length > 3) continue
              ws['!cols'].push({ width: 20 })
            }

            if (this.model.type == 2) {
              const styleheaderrow = {
                font: {
                  sz: 16,
                  name: 'Angsana New',
                  color: '000000',
                },
                fill: {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { rgb: 'F3EFC4' },
                },
              }

              for (let index = 0; index < data.length; index++) {
                if (data[index]['#'] != '') {
                  for (let i = 65; i <= 90; i++) {
                    const letter = String.fromCharCode(i) + (index + 2).toString()
                    const letter2 = 'A' + String.fromCharCode(i) + (index + 2).toString()

                    ws[letter].s = styleheaderrow
                    if (ws[letter2]) {
                      ws[letter2].s = styleheaderrow
                    }
                  }
                }
              }
              ws['!cols'].push({ width: 30 }, { width: 30 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 })
            }
            ws['!rows'] = [{ hpt: 40 }]
            for (let index = 0; index < data.length; index++) {
              ws['!rows'].push({ hpt: 30 })
            }
            wb.Sheets[SheetNames] = ws
          }
          const wbout = ELSX.write(wb, { bookType: 'xlsx', type: 'base64' })

          const filePath = 'data:application/octet-stream;base64,' + wbout
          const a = document.createElement('A') as any
          a.href = filePath

          const date = moment().format('YYYY-MM-DD')
          let name = `${date}_${order_status['']}_`

          let text = ''
          for (const status of this.model.status) {
            if (text == '') {
              text += order_status[status]
            } else {
              text += 'และ' + order_status[status]
            }
          }

          if (this.model.paymenttype == '') {
            name = `${filename} | ${date}_${text}_ทั้งหมด`
          } else if (this.model.paymenttype == '1') {
            name = `${filename} | ${date}_${text}_ชำระเงินสด`
          } else if (this.model.paymenttype == '2') {
            name = `${filename} | ${date}_${text}_โอนเงินผ่านธนาคาร`
          } else if (this.model.paymenttype == '3') {
            name = `${filename} | ${date}_${text}_CreditCard`
          } else if (this.model.paymenttype == '4') {
            name = `${filename} | ${date}_${text}_เก็บเงินปลายทาง`
          } else if (this.model.paymenttype == '5') {
            name = `${filename} | ${date}_${text}_QRCode`
          }

          if (this.model.type == 6) {
            name = `${filename} ${date}`
          }

          if (this.model.type !== 4 && this.model.type !== 6 && res.length == 0) {
            let name = ''
            const paymenttype = `โดย ${patment_status[this.model.paymenttype]}`

            if (this.model.status.length == 0) {
              name = `ไม่มีออเดอร์ สถานะทั้งหมด โดยการชำระเงินทั้งหมด`
            } else {
              const c = ''
              for (const status of this.model.status) {
                if (text == '') {
                  text += order_status[status]
                } else {
                  text += 'และ' + order_status[status]
                }
              }
              name = `ไม่มีออเดอร์  สถานะ${text} ${paymenttype}`
            }
            this.modal.warning({
              nzTitle: name,
              nzContent: '',
            })
            $('.theme-loader').hide()
            return
          }

          a.download = `${name}.xlsx`
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        } catch (err) {
          this.modal.error({
            nzContent: err,
          })
        }
        $('.theme-loader').hide()
      })
    // .catch((res: any) => {
    //   console.log(res)
    //   $('.theme-loader').hide()
    // })
  }
  setnametype1() {
    let _order_status = ''
    for (const status of this.model.status) {
      if (_order_status == '') {
        if (status == '') {
          _order_status = 'ทุกสถานะ'
        } else if (status == '-1') {
          _order_status = 'ยกเลิก'
        } else if (status == '1') {
          _order_status = 'รอชำระเงิน'
        } else if (status == '2') {
          _order_status = 'ได้รับเงิน'
        } else if (status == '3') {
          _order_status = 'จัดเตรียมสินค้า'
        } else if (status == '3.5') {
          _order_status = 'เตรียมจัดส่ง'
        } else if (status == '4') {
          _order_status = 'จัดส่ง'
        } else if (status == '5') {
          _order_status = 'เสร็จสิ้น'
        }
      } else {
        _order_status += 'และ'
        if (status == '') {
          _order_status += 'ทุกสถานะ'
        } else if (status == '-1') {
          _order_status += 'ยกเลิก'
        } else if (status == '1') {
          _order_status += 'รอชำระเงิน'
        } else if (status == '2') {
          _order_status += 'ได้รับเงิน'
        } else if (status == '3') {
          _order_status += 'จัดเตรียมสินค้า'
        } else if (status == '3.5') {
          _order_status += 'เตรียมจัดส่ง'
        } else if (status == '4') {
          _order_status += 'จัดส่ง'
        } else if (status == '5') {
          _order_status += 'เสร็จสิ้น'
        }
      }
    }

    let filename = ''
    if (this.model.type == '1') {
      filename = 'สรุปยอดบิล'
    } else if (this.model.type == '2') {
      filename = 'สรุปยอดบิลและรายละเอียด'
    } else if (this.model.type == '3') {
      filename = 'รายการสินค้าที่ขายได้'
    } else if (this.model.type == '4') {
      filename = 'สรุปยอดทุกช่องทาง'
    } else if (this.model.type == '6') {
      filename = 'สรุปยอดบิลและสินค้า'
    }
    const date = moment().format('YYYY-MM-DD')
    let name = `${date}_${_order_status}_.xlsx`

    if (this.model.paymenttype == '') {
      name = `${filename} | ${date}_${_order_status}_ทั้งหมด.xlsx`
    } else if (this.model.paymenttype == 1) {
      name = `${filename} | ${date}_${_order_status}_ชำระเงินสด.xlsx`
    } else if (this.model.paymenttype == 2) {
      name = `${filename} | ${date}_${_order_status}_โอนเงินผ่านธนาคาร.xlsx`
    } else if (this.model.paymenttype == 3) {
      name = `${filename} | ${date}_${_order_status}_CreditCard.xlsx`
    } else if (this.model.paymenttype == 4) {
      name = `${filename} | ${date}_${_order_status}_เก็บเงินปลายทาง.xlsx`
    } else if (this.model.paymenttype == 5) {
      name = `${filename} | ${date}_${_order_status}_QRCode.xlsx`
    }
    return name
  }
  setnametype2(filename: string) {
    let _order_status = ''
    for (const status of this.model.status) {
      if (_order_status == '') {
        if (status == '') {
          _order_status = 'ทุกสถานะ'
        } else if (status == '-1') {
          _order_status = 'ยกเลิก'
        } else if (status == '1') {
          _order_status = 'รอชำระเงิน'
        } else if (status == '2') {
          _order_status = 'ได้รับเงิน'
        } else if (status == '3') {
          _order_status = 'จัดเตรียมสินค้า'
        } else if (status == '3.5') {
          _order_status = 'เตรียมจัดส่ง'
        } else if (status == '4') {
          _order_status = 'จัดส่ง'
        } else if (status == '5') {
          _order_status = 'เสร็จสิ้น'
        }
      } else {
        _order_status += 'และ'
        if (status == '') {
          _order_status += 'ทุกสถานะ'
        } else if (status == '-1') {
          _order_status += 'ยกเลิก'
        } else if (status == '1') {
          _order_status += 'รอชำระเงิน'
        } else if (status == '2') {
          _order_status += 'ได้รับเงิน'
        } else if (status == '3') {
          _order_status += 'จัดเตรียมสินค้า'
        } else if (status == '3.5') {
          _order_status += 'เตรียมจัดส่ง'
        } else if (status == '4') {
          _order_status += 'จัดส่ง'
        } else if (status == '5') {
          _order_status += 'เสร็จสิ้น'
        }
      }
    }
    const date = moment().format('YYYY-MM-DD')
    let name = `${date}_${_order_status}_.xlsx`
    if (this.model.paymenttype == '') {
      name = `${filename} | ${date}_${_order_status}_ทั้งหมด.xlsx`
    } else if (this.model.paymenttype == 1) {
      name = `${filename} | ${date}_${_order_status}_ชำระเงินสด.xlsx`
    } else if (this.model.paymenttype == 2) {
      name = `${filename} | ${date}_${_order_status}_โอนเงินผ่านธนาคาร.xlsx`
    } else if (this.model.paymenttype == 3) {
      name = `${filename} | ${date}_${_order_status}_CreditCard.xlsx`
    } else if (this.model.paymenttype == 4) {
      name = `${filename} | ${date}_${_order_status}_เก็บเงินปลายทาง.xlsx`
    } else if (this.model.paymenttype == 5) {
      name = `${filename} | ${date}_${_order_status}_QRCode.xlsx`
    }
    return name
  }
  ok() {
    if (this.model.type == 'TRCloud') {
      this.exportTRCloud()
      return false
    } else {
      this.export()
    }
  }

  shippopName(courier_code) {
    const shippop_name = {
      THP: 'ไปรษณีย์ไทย EMS',
      EMST: 'ไปรษณีย์ไทย EMS',
      TP2: 'ไปรษณีย์ ลงทะเบียน',
      ECP: 'ไปรษณีย์ไทย (Eco-post)',
      APF: 'Alphafast',
      KRE: 'Kerry Express',
      KRYD: 'Kerry Express Dropoff',
      KRYP: 'Kerry Express Pickup',
      JNTD: 'J&T Express Dropoff',
      JNTP: 'J&T Express Pickup',
      RSB: 'Rush Bike',
      BEST: 'Best Express',
      SKT: 'Skootar',
      GRE: 'Grab Express',
      GRB: 'Grab Bike',
      DHL: 'DHL',
      LLM: 'Lalamove',
      NIK: 'Niko Logistic',
      SCG: 'SCG Express',
      NJV: 'Ninjavan',
      SCGF: 'SCG Express -15c',
      SCGC: 'SCG Express -8c',
      CJE: 'CJ Logistics',
      FLE: 'Flash Express',
      TRUE: 'True e-Logistics',
    }

    if (courier_code) {
      return shippop_name[courier_code]
    } else {
      return ''
    }
  }

  createWorkSheetReport(order_json) {
    const order_status = {
      '-1': 'ยกเลิก',
      '0': 'Pending',
      '1': 'รอชำระเงิน',
      '2': 'ได้รับเงิน',
      '3': 'จัดเตรียมสินค้า',
      '3.5': 'เตรียมจัดส่ง',
      '4': 'จัดส่ง',
    }

    const j = 2
    const test = []
    let k = 0
    for (let i = 0; i < order_json.length; i++) {
      const ord = order_json[i]

      for (const obj of ord.details) {
        if (test.length == 0) {
          if (order_status[ord.status] == 'เสร็จสิ้น' || ord.order_complete) {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: 0,
              prepare: 0,
              prepare_delivery: 0,
              payment: 0,
              success: obj.qty,
              delivery: 0,
              paid: 0,
            })
          } else if (order_status[ord.status] == 'ยกเลิก') {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: obj.qty,
              prepare: 0,
              prepare_delivery: 0,
              payment: 0,
              success: 0,
              delivery: 0,
              paid: 0,
            })
          } else if (order_status[ord.status] == 'จัดเตรียมสินค้า') {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: 0,
              prepare: obj.qty,
              prepare_delivery: 0,
              payment: 0,
              success: 0,
              delivery: 0,
              paid: 0,
            })
          } else if (ord.status == 3.5) {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: 0,
              prepare: 0,
              prepare_delivery: obj.qty,
              payment: 0,
              success: 0,
              delivery: 0,
              paid: 0,
            })
          } else if (order_status[ord.status] == 'รอชำระเงิน') {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: 0,
              prepare: 0,
              prepare_delivery: 0,
              payment: obj.qty,
              success: 0,
              delivery: 0,
              paid: 0,
            })
          } else if (order_status[ord.status] == 'จัดส่ง') {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: 0,
              prepare: 0,
              prepare_delivery: 0,
              payment: 0,
              success: 0,
              delivery: obj.qty,
              paid: 0,
            })
          } else if (order_status[ord.status] == 'ได้รับเงิน') {
            test.push({
              sku: obj.sku,
              title_lang1: obj.title_lang1,
              qty: obj.qty,
              cancel: 0,
              prepare: 0,
              prepare_delivery: 0,
              payment: 0,
              success: 0,
              delivery: 0,
              paid: obj.qty,
            })
          }
          k++
        } else {
          let a = 0
          for (let index = 0; index < test.length; index++) {
            if (test[index].sku != obj.sku) {
              a++
            } else {
              if (order_status[ord.status] == 'เสร็จสิ้น' || ord.order_complete) {
                test[index].success += obj.qty
              } else if (order_status[ord.status] == 'ยกเลิก') {
                test[index].cancel += obj.qty
              } else if (order_status[ord.status] == 'จัดเตรียมสินค้า') {
                test[index].prepare += obj.qty
              } else if (ord.status == 3.5) {
                test[index].prepare_delivery += obj.qty
              } else if (order_status[ord.status] == 'รอชำระเงิน') {
                test[index].payment += obj.qty
              } else if (order_status[ord.status] == 'จัดส่ง') {
                test[index].delivery += obj.qty
              } else if (order_status[ord.status] == 'ได้รับเงิน') {
                test[index].paid += obj.qty
              }
              test[index].qty += obj.qty
            }
          }
          if (a == test.length) {
            k++
            if (order_status[ord.status] == 'เสร็จสิ้น' || ord.order_complete) {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: 0,
                prepare: 0,
                prepare_delivery: 0,
                payment: 0,
                success: obj.qty,
                delivery: 0,
                paid: 0,
              })
            } else if (order_status[ord.status] == 'ยกเลิก') {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: obj.qty,
                prepare: 0,
                prepare_delivery: 0,
                payment: 0,
                success: 0,
                delivery: 0,
                paid: 0,
              })
            } else if (order_status[ord.status] == 'จัดเตรียมสินค้า') {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: 0,
                prepare: obj.qty,
                prepare_delivery: 0,
                payment: 0,
                success: 0,
                delivery: 0,
                paid: 0,
              })
            } else if (ord.status == 3.5) {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: 0,
                prepare: 0,
                prepare_delivery: obj.qty,
                payment: 0,
                success: 0,
                delivery: 0,
                paid: 0,
              })
            } else if (order_status[ord.status] == 'รอชำระเงิน') {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: 0,
                prepare: 0,
                prepare_delivery: 0,
                payment: obj.qty,
                success: 0,
                delivery: 0,
                paid: 0,
              })
            } else if (order_status[ord.status] == 'จัดส่ง') {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: 0,
                prepare: 0,
                prepare_delivery: 0,
                payment: 0,
                success: 0,
                delivery: obj.qty,
                paid: 0,
              })
            } else if (order_status[ord.status] == 'ได้รับเงิน') {
              test.push({
                sku: obj.sku,
                title_lang1: obj.title_lang1,
                qty: obj.qty,
                cancel: 0,
                prepare: 0,
                prepare_delivery: 0,
                payment: 0,
                success: 0,
                delivery: 0,
                paid: obj.qty,
              })
            }
          }
        }
      }
    }
    return test
  }

  exportTRCloud() {
    $('.theme-loader').show()
    this.Service_Order.exportTRCloud(this.model)
      .then((res: any) => {
        $('.theme-loader').hide()
        if (res.body.length === 0) {
          this.modal.error({
            nzTitle: 'ไม่พบข้อมูล',
            nzContent: '',
          })
          return
        }
        try {
          const name = this.setnametype2('Order-TRCloud')
          const wb = XLSX.utils.book_new()
          const SheetNames = 'head'
          const sheetBody = 'body'
          wb.Props = {
            Title: SheetNames,
            CreatedDate: new Date(),
          }
          wb.SheetNames.push(SheetNames)
          let ws = XLSX.utils.json_to_sheet(res.head)
          wb.Sheets[SheetNames] = ws
          wb.SheetNames.push(sheetBody)
          ws = XLSX.utils.json_to_sheet(res.body)
          wb.Sheets[sheetBody] = ws

          const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'base64' })
          const filePath = 'data:application/octet-stream;base64,' + wbout
          const a = document.createElement('a') as any
          a.href = filePath
          a.download = name
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        } catch (err) {
          console.log(err)
          this.modal.error({
            nzContent: 'ไม่สามารถดาว์โหลดไฟล์ได้',
          })
        }
        console.log(res)
      })
      .catch((data: any) => {
        $('.theme-loader').hide()
        console.log(data)
      })
  }

  Change() {
    if (this.model.status[this.model.status.length - 1] == '') {
      this.model.status = ['']
    } else {
      this.model.status = this.model.status.filter((x) => x != '')
    }
  }
}
