import { HttpClient } from '@angular/common/http'
import { inject, Injectable } from '@angular/core'
import { IProductsState } from '@store/ketshopweb-store'
import { Observable } from 'rxjs'
import { IPriceLevel } from './types/IPriceLevel'

@Injectable({
  providedIn: 'root',
})
export class CmsProductsService {
  private http = inject(HttpClient)

  systemSearch(model: IProductsState.SearchModel) {
    return this.http.post(`/services/ketshopweb-products/products/system_search`, model) as Observable<{
      count: number
      data: IProductsState.ProductItem[]
    }>
  }

  getProductRelateds(product_id: number) {
    return this.http.get(`/services/ketshopweb-products/products/${product_id}/product_relateds`)
  }

  saveProductRelateds(product_id: number, data: { related_product_setting: string; product_related_ids: number[] }) {
    return this.http.post(`/services/ketshopweb-products/products/${product_id}/product_relateds`, data)
  }

  // /api/v1/product/maincategory
  mainCategory() {
    return this.http.get(`/services/v2/ketcms-frontend/products/main_category`) as Observable<
      IProductsState.CategoryItem[]
    >
  }

  getMapColumnsImport(type = '') {
    let qry = ''
    if (type) {
      qry = `?type=${type}`
    }
    return this.http.get('/services/ketshopweb-products/imports/getMapColumns' + qry).toPromise()
  }

  uploadFile(data: any) {
    return this.http.post('/services/ketshopweb-products/imports/upload_file', data).toPromise()
  }

  product_background_mode(data: { type: string; map_columns: any[]; xlsx_path: string; file_name: string }) {
    return this.http
      .post('/services/ketshopweb-products/imports/product_background_mode', data)
      .toPromise() as Promise<{
      job_id: any
      job_ref: string
    }>
  }

  import_product_price_background_mode(data:{
    xlsx_path: string
    file_name: string
  }){
    return this.http
      .post('/services/ketshopweb-products/imports/import_product_price_background_mode', data)
      .toPromise() as Promise<{
      job_id: any
      job_ref: string
    }>
  }

  import_marketplace_background_mode(data: {
    type: string
    xlsx_path: string
    file_name: string
    map_columns?: any[]
  }) {
    return this.http
      .post('/services/ketshopweb-products/imports/import_marketplace_background_mode', data)
      .toPromise() as Promise<{
      job_id: any
      job_ref: string
    }>
  }

  background_mode_state(ref: string) {
    return this.http.get(`/services/ketshopweb-products/imports/background_mode_state/${ref}`).toPromise() as Promise<{
      state: 'success' | 'process' | 'timeout'
      created_at?: string | null
      created_by?: string | null
      failed?: number | null
      file_name?: string | null
      id?: number
      import_by?: string | null
      import_date?: string | null
      import_time?: string | null
      map_columns?: string | null
      path?: string | null
      ref?: string | null
      success?: number | null
      total?: number | null
      type?: string | null
      updated_at?: string | null
      updated_by?: string | null
      warehouse_receiver?: number | null
      warehouse_sender?: number | null
      details: {
        log_file_import_id: number
        sku: string
        name: string
        status: 0 | 1
        error_message: string
        amount: number
      }[]
    }>
  }

  download_error_product_marketplace(ref: string) {
    return this.http
      .get(`/services/ketshopweb-products/imports/download_error_product_marketplace/${ref}`)
      .toPromise() as Promise<any>
  }

  download_error_import_product_price(ref: string) {
    return this.http
      .get(`/services/ketshopweb-products/imports/download_error_import_product_price/${ref}`)
      .toPromise() as Promise<any>
  }

  download_error_product(ref: string) {
    return this.http
      .get(`/services/ketshopweb-products/imports/download_error_product/${ref}`)
      .toPromise() as Promise<any>
  }

  searchPriceLevel(model: IPriceLevel.SearchModel) {
    return this.http.post(`/services/ketshopweb-products/products/search_price_level`, model) as Observable<{
      count: number
      data: IPriceLevel.ProductPriceLevel[]
      level_label: IPriceLevel.LevelLabel[]
    }>
  }

  // set_price_level
  setPriceLevel(model: IPriceLevel.SetPriceLevelInput) {
    return this.http.post(`/services/ketshopweb-products/products/set_price_level`, model) as Observable<{
      status: string
    }>
  }
}
